.App {
  text-align: center;
}

.App-logo {
  border-radius: 50%;
  object-fit: cover;
  height: 40vmin;
  pointer-events: none;
}


.App-header {
  background-color: #FFEDD9;
    background-image: linear-gradient(141deg, #9fb8ad 0%, #FFEDD9 51%, #ffdad9 75%);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #235951;
}

.App-link {
  color: #61dafb;
}